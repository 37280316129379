import { render, staticRenderFns } from "./CompetenceProDetail.vue?vue&type=template&id=30b7d9fc&scoped=true&"
import script from "./CompetenceProDetail.vue?vue&type=script&lang=js&"
export * from "./CompetenceProDetail.vue?vue&type=script&lang=js&"
import style0 from "./CompetenceProDetail.vue?vue&type=style&index=0&id=30b7d9fc&prod&lang=css&"
import style1 from "@/assets/styles/CrudDetail.css?vue&type=style&index=1&id=30b7d9fc&prod&scoped=true&lang=css&"
import style2 from "./CompetenceProDetail.vue?vue&type=style&index=2&id=30b7d9fc&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "30b7d9fc",
  null
  
)

export default component.exports