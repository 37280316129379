import { render, staticRenderFns } from "./BodyTitle.vue?vue&type=template&id=c8b321dc&scoped=true&"
import script from "./BodyTitle.vue?vue&type=script&lang=js&"
export * from "./BodyTitle.vue?vue&type=script&lang=js&"
import style0 from "./BodyTitle.vue?vue&type=style&index=0&id=c8b321dc&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c8b321dc",
  null
  
)

export default component.exports