import { render, staticRenderFns } from "./Notes.vue?vue&type=template&id=a6f54d66&scoped=true&"
import script from "./Notes.vue?vue&type=script&lang=js&"
export * from "./Notes.vue?vue&type=script&lang=js&"
import style0 from "@/assets/styles/CrudListComponent.css?vue&type=style&index=0&id=a6f54d66&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a6f54d66",
  null
  
)

export default component.exports