import { render, staticRenderFns } from "./CursusEtudiant.vue?vue&type=template&id=5005ed93&scoped=true&"
import script from "./CursusEtudiant.vue?vue&type=script&lang=js&"
export * from "./CursusEtudiant.vue?vue&type=script&lang=js&"
import style0 from "./CursusEtudiant.vue?vue&type=style&index=0&id=5005ed93&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5005ed93",
  null
  
)

export default component.exports