import { render, staticRenderFns } from "./DossierProfessionnel_modifier.vue?vue&type=template&id=f1fe6886&scoped=true&"
import script from "./DossierProfessionnel_modifier.vue?vue&type=script&lang=js&"
export * from "./DossierProfessionnel_modifier.vue?vue&type=script&lang=js&"
import style0 from "./DossierProfessionnel_modifier.vue?vue&type=style&index=0&id=f1fe6886&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f1fe6886",
  null
  
)

export default component.exports