export default class ActiviteType {
  
  constructor(id, version,libelle,numeroFiche,cursusActiviteTypeId) {
    this.id = id;
    this.version = version;
    this.libelle = libelle;
    this.numeroFiche = numeroFiche;
    this.cursusActiviteTypeId =cursusActiviteTypeId
  }
}
