import { render, staticRenderFns } from "./DossierPro.vue?vue&type=template&id=531d6fbd&scoped=true&"
import script from "./DossierPro.vue?vue&type=script&lang=js&"
export * from "./DossierPro.vue?vue&type=script&lang=js&"
import style0 from "./DossierPro.vue?vue&type=style&index=0&id=531d6fbd&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "531d6fbd",
  null
  
)

export default component.exports