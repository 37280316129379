import { render, staticRenderFns } from "./NoteListComponent.vue?vue&type=template&id=2e6965f2&scoped=true&"
import script from "./NoteListComponent.vue?vue&type=script&lang=js&"
export * from "./NoteListComponent.vue?vue&type=script&lang=js&"
import style0 from "@/assets/styles/CrudListComponent.css?vue&type=style&index=0&id=2e6965f2&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2e6965f2",
  null
  
)

export default component.exports