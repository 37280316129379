var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container-fluid"},[_c('a',{staticClass:"h5",staticStyle:{"cursor":"pointer","color":"black","text-decoration":"none"},on:{"click":function($event){return _vm.goBack()}}},[_c('font-awesome-icon',{staticClass:"icon",attrs:{"icon":['fas', 'chevron-left']}}),_vm._v(" Précédent ")],1),_c('BodyTitle',{attrs:{"title":_vm.vue_title}}),_c('b-form',{staticClass:"form mb-5",on:{"submit":_vm.submit}},[_c('b-form-group',{attrs:{"id":"form-group"}},[_c('b-form-row',{staticClass:"text-align-left"},[_c('label',[_vm._v("Etudiant")]),_c('div',{staticClass:"mon-input"},[_c('b-form-input',{attrs:{"type":"text","disabled":"disabled"},model:{value:(_vm.etudiant_input),callback:function ($$v) {_vm.etudiant_input=$$v},expression:"etudiant_input"}})],1)])],1),_c('b-form-group',{attrs:{"id":"form-group"}},[_c('b-form-row',{staticClass:"text-align-left"},[_c('label',[_vm._v("Note")]),_c('div',{staticClass:"mon-input"},[_c('b-form-input',{attrs:{"type":"number"},model:{value:(_vm.form.noteObtenu),callback:function ($$v) {_vm.$set(_vm.form, "noteObtenu", $$v)},expression:"form.noteObtenu"}})],1)])],1),_c('b-form-group',{attrs:{"id":"form-group"}},[_c('b-form-row',{staticClass:"text-align-left"},[_c('label',[_vm._v("Observations")]),_c('div',{staticClass:"mon-input"},[_c('b-form-input',{attrs:{"type":"text"},model:{value:(_vm.form.observations),callback:function ($$v) {_vm.$set(_vm.form, "observations", $$v)},expression:"form.observations"}})],1)])],1),_c('div',{staticClass:"mon-container-tuile"},[_c('div',{class:{
          btn: true,
          'btn-primary': true,
          ma_tuile: false,
          activ: _vm.isExamen,
        },on:{"click":function($event){return _vm.changementOnglet(1)}}},[_vm._v(" Examen ")]),_c('div',{class:{
          btn: true,
          'btn-primary': true,
          ma_tuile: false,
          activ: _vm.isDevoir,
        },on:{"click":function($event){return _vm.changementOnglet(2)}}},[_vm._v(" Devoir ")])]),_c('PassageExamenListComponent',{class:{ collapse: !_vm.isExamen },attrs:{"passageExamenProp":_vm.passageExamen_input},on:{"click-list":_vm.onClickChildPassageExamenList}}),_c('DevoirListComponent',{class:{ collapse: !_vm.isDevoir },attrs:{"devoirProp":_vm.devoir_input},on:{"click-list":_vm.onClickChildDevoirList}}),_c('div',{staticClass:"offset-10 col-3 pr-5 pl-0"},[_c('button',{staticClass:"btn btn-primary mon-btn",attrs:{"type":"submit"}},[_vm._v(" "+_vm._s(_vm.btn_form_text)+" ")])])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }